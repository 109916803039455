import { createRouter, createWebHistory } from "vue-router";
// import HomeView from '../views/HomeView.vue'
import { checkLogin } from "../components/firebase/authentication";

const routes = [
  //Super admin
  {
    path: "/administracionApp",
    name: "AdministracionApp",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/administracion/Administracion.vue"),
  },
  {
    path: "/admin",
    name: "AdminTool",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../views/AdminTool.vue"),
  },
  {
    path: "/arbolDeMando",
    name: "Mando App",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/administracion/GobernadorMasFuerte.vue"),
  },
  //fin
  {
    path: "/",
    name: "Home",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/MainTrabajadores.vue"),
    // component: HomeView
  },
  {
    path: "/gastos",
    name: "Gastos",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/coordinadora/Gastos.vue"),
  },
  {
    path: "/crear-anuncio",
    name: "Nuevo anuncio",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/rrhh/CrearAnuncio.vue"),
  },
  {
    path: "/anuncios",
    name: "Anuncios",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/TablonAnuncios.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/registro",
    name: "Registro",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../views/Registro.vue"),
  },
  {
    path: "/restablecer",
    name: "Restablecer contraseña",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../views/Restablecer.vue"),
  },
  {
    path: "/privacidad",
    name: "Privacidad",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../components/Privacidad.vue"),
  },
  {
    path: "/tarifas-especiales",
    name: "Tarifas especiales",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tpv/TarifasEspeciales.vue"),
  },
  {
    path: "/menu-rrhh",
    name: "Menú recursos humanos",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/rrhh/MenuPrincipalRRHH.vue"),
  },
  {
    path: "/menu-tpv",
    name: "Menú TPV",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tpv/MenuPrincipalTPV.vue"),
  },
  {
    path: "/validar-horas",
    name: "Validar horas",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/coordinadora/ValidarHoras.vue"),
  },
  {
    path: "/nueva-tarifa-especial",
    name: "Nueva tarifa especial",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tpv/NuevaTarifaEspecial.vue"),
  },
  // Portal empleado
  {
    path: "/portal-empleado",
    name: "Portal de empleado",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/MainTrabajadores.vue"),
  },
  {
    path: "/pedir-vacaciones",
    name: "Pedir vacaciones",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/PedirVacaciones.vue"),
  },
  {
    path: "/pedir-mis-vacaciones",
    name: "Pedir mis vacaciones",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/tienda/PedirMisVacaciones.vue"),
  },
  {
    path: "/mis-bajas",
    name: "Mis Bajas",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/MisBajas.vue"),
  },
  {
    path: "/enviar-baja",
    name: "Enviar Baja",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/EnviarBaja.vue"),
  },
  {
    path: "/chat",
    name: "Chat con empresa",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/ChatConRecursosH.vue"),
  },
  {
    path: "/nominas-personal",
    name: "nominas personal",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/NominasTrabajadores.vue"),
  },
  {
    path: "/documentos",
    name: "Documentos",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/Documentos.vue"),
  },
  {
    path: "/mis-vacaciones",
    name: "mis vacaciones",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/MisVacaciones.vue"),
  },
  {
    path: "/test",
    name: "test",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/test.vue"),
  },
  {
    path: "/fichajes",
    name: "fichajes",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/Fichajes.vue"),
  },
  {
    path: "/miPerfil",
    name: "mi perfil",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/MiPerfil.vue"),
  },
  //Coordinadora
  {
    path: "/gestion-tienda",
    name: "gestion tienda",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../views/tienda/coordinadora/MenuPrincipalCoordinadora.vue"),
  },
  {
    path: "/gestionar-tiendas-supervisoras",
    name: "gestionar tiendas",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/supervisora/MainSupervisora.vue"),
  },
  {
    path: "/subir-cuadrante",
    name: "subir cuadrante",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/coordinadora/SubirCuadrantes.vue"),
  },
  {
    path: "/cuadrantes-tienda",
    name: "crearcuadrantes tienda",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/coordinadora/VerCuadrante.vue"),
  },
  {
    path: "/verValidadas-tienda",
    name: "validarADMIN",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/tienda/coordinadora/validarHoras/verValidadosAdmin.vue"
      ),
  },
  {
    path: "/abrir-incidencia",
    name: "Abrir Incidencias",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import("../components/general/Incidencias/Incidencias.vue"),
  },
  {
    path: "/bolsa-horas-tienda",
    name: "Bolsa tienda",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import("../components/tienda/coordinadora/BolsaHorasTienda.vue"),
  },
  //Gestion tienda
  {
    path: "/gestionar-tiendas",
    name: "Gestión tiendas",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/administracion/MenuGestionTiendas.vue"),
  },
  {
    path: "/crearAuditoria",
    name: "Crear auditoria",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/general/auditorias/CrearAuditoria.vue"),
  },
  {
    path: "/auditorias",
    name: "Auditorias",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/general/auditorias/AuditoriasIndex.vue"),
  },
  {
    path: "/auditorias-tienda",
    name: "Auditorias tienda",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/general/auditorias/AuditoriasTienda.vue"),
  },
  {
    path: "/miEquipo",
    name: "MiEquipo",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/general/MiEquipo.vue"),
  },
  {
    path: "/proximosCumples",
    name: "ProximosCumpleaños",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/general/ProximosCumpleaños.vue"),
  },
  // Recursos humanos
  {
    path: "/recursos-humanos",
    name: "Recursos humanos",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/rrhh/MenuPrincipalRRHH.vue"),
  },
  {
    path: "/subir-bolsa-horas",
    name: "Subir Bolsa de horas",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/rrhh/SubirBolsaHoras.vue"),
  },
  {
    path: "/mi-bolsa",
    name: "Mi bolsa",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/MiBolsaHoras.vue"),
  },
  {
    path: "/bolsa-horas",
    name: "Bolsa horas",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/rrhh/BolsaHorasIndex.vue"),
  },
  {
    path: "/usuarios",
    name: "usuarios",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/rrhh/Users/usuariosIndex.vue"),
  },
  {
    path: "/vacaciones",
    name: "Vacaciones",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/supervisora/VacacionesIndex.vue"),
  },
  {
    path: "/vacaciones-admin",
    name: "Vacaciones RRHH",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/rrhh/VacacionesAdmin.vue"),
  },
  {
    path: "/PedirVacacionesAdmin",
    name: "PedirVacacionesAdmin",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/rrhh/PedirVacacionesAdmin.vue"),
  },
  {
    path: "/bajas",
    name: "bajas",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/rrhh/BajasIndex.vue"),
  },
  {
    path: "/portales",
    name: "Portales",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/Main.vue"),
  },
  {
    path: "/gestor-teclados",
    name: "Gestor de teclados",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/tpv/GestorTeclado.vue"),
  },
  {
    path: "/tiendas-asignadas",
    name: "Tiendas asignadas",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/supervisora/TiendasAsignadas.vue"),
  },
  {
    path: "/cuadrantes-tiendas",
    name: "cuadrantes tiendas",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/rrhh/CuadrantesIndex.vue"),
  },
  {
    path: "/validarQR-trabajador",
    name: "validarQR trabajadir",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../components/general/ValidarQR.vue"),
  },
  {
    path: "/ausencias",
    name: "ausencias",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/rrhh/Ausencias.vue"),
  },
  {
    path: "/horasPagar",
    name: "horasPagar",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/rrhh/HorasPagar.vue"),
  },
  {
    path: "/notificaciones",
    name: "notificaciones",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/general/NotificacionesIndex.vue"),
  },
  {
    path: "/perfil",
    name: "perfil",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/general/PerfilIndex.vue"),
  },
  {
    path: "/abrirIncidenciaInvitado",
    name: "abrirIncidenciaInvitado",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import("../components/general/Incidencias/IncidenciasInvitado.vue"),
  },
  {
    path: "/archivoDigital",
    name: "archivoDigital",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/general/ArchivoDigital.vue"),
  },
  {
    path: "/archivoDigitalAdmin",
    name: "archivoDigitalAdmin",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/rrhh/ArchivoDigitalAdmin.vue"),
  },
  {
    path: "/consultarFichaje",
    name: "consultarFichaje",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/general/Fichajes/ConsultarFichaje.vue"),
  },
  {
    path: "/calendarioFestivos",
    name: "calendarioFestivos",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/general/CalendarioFestivo.vue"),
  },
  {
    path: "/calendarioEventos",
    name: "calendarioEventos",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/general/CalendarioEventos.vue"),
  },
  {
    path: "/ausenciaEquipo",
    name: "ausenciaEquipo",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/general/AusenciasEquipo.vue"),
  },
  {},
  {
    path: "/CrearEvaluacion",
    name: "CrearEvaluacion",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/general/Evaluaciones/Dashboard/plantillas/CrearEvaluacion.vue"
      ),
  },
  {
    path: "/evaluaciones",
    name: "Evaluaciones Index",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/general/Evaluaciones/Dashboard/EvaluacionesIndex.vue"
      ),
  },
  {
    path: "/evaluaciones-equipo",
    name: "Evaluaciones Equipo",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/general/Evaluaciones/Equipos/EvaluacionesIndexEquipos.vue"
      ),
  },
  {
    path: "/analytics",
    name: "Analytics",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/rrhh/AnalyticsAdmin.vue"),
  },
  {
    path: "/invitacionesNavidad",
    name: "InvitacionesNavidad",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/rrhh/InvitaciónNavidad.vue"),
  },
  {
    path: "/calendarioLaboral",
    name: "Calendario Laboral",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/rrhh/CalendarioLaboral.vue"),
  },
  {
    path: "/videoCultura",
    name: "Cultura 365",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/general/Cultura365/videoCultura.vue"),
  },
  {
    path: "/crear-video",
    name: "Nuevo video",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/general/Cultura365/CrearVideo.vue"),
  },
  {
    path: "/informe-pactado-real",
    name: "Informe pactado vs real",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/tienda/coordinadora/validarHoras/informePactadoVsReal.vue"
      ),
  },
  {
    path: "/company-management",
    name: "Gestión de empresas",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/rrhh/GestionEmpresas.vue"),
  },
  {
    path: "/crear-empresa",
    name: "Crear empresa",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/rrhh/CrearEmpresa.vue"),
  },
  {
    path: "/crearIluo",
    name: "crear ILUO",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/general/Evaluaciones/Dashboard/plantillas/CrearILUO.vue"
      ),
  },
  {
    path: "/manage-role-permissions",
    name: "Gestión de roles y permisos",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/rrhh/RolesPermisos.vue"),
  },
  {
    path: "/misEvaluaciones",
    name: "Mis Evaluaciones",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/general/Evaluaciones/Equipos/MisEvaluaciones.vue"),
  },
  {
    path: "/misdiasPersonales",
    name: "MisDiasPersonales",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/general/Dia Personal/MisDiasPersonales.vue"),
  },
  {
    path: "/pedirMiDiaPersonal",
    name: "PedirMiDiaPersonal",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/general/Dia Personal/PedirMiDiaPersonal.vue"),
  },
  {
    path: "/diaPersonal",
    name: "diaPersonal",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/general/Dia Personal/DiaPersonal.vue"),
  },
  {
    path: "/canalDenuncias",
    name: "Canal de denuncias",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/tienda/CanalDenuncias.vue"),
  },
  {
    path: "/kpis-tiendas",
    name: "KPI",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/administracion/KPIs/admin/KPIsView.vue"),
  },
  {
    path: "/menuMiTienda",
    name: "Menu Tienda",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/tienda/MenuMiTienda.vue"),
  },
  {
    path: "/kpiTiendaIndex",
    name: "KPI Tienda",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/administracion/KPIs/KpiTiendaIndex.vue"),
  },
  {
    path: "/clientesRegistradosFlayer",
    name: "Clientes Registrados Flayer",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/Marketing/ClientesRegistradosFlayer.vue"),
  },
  {
    path: "/canalDenuncias",
    name: "Canal de denuncias",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/tienda/CanalDenuncias.vue"),
  },
  {
    path: "/agenda",
    name: "Agenda",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/rrhh/Agenda.vue"),
  },
  {
    path: "/validarFlayer",
    name: "Flayer",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../components/Marketing/ValidarFlayer.vue"),
  },
  {
    path: "/mensajesDistribucion",
    name: "Mensajes de Distribucion",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import(
        "../components/general/mensajesDistribucion/MensajesDistribucion.vue"
      ),
  },
  {
    path: "/videoFormacion",
    name: "Videos Formacion",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/general/VideosFormacion/VideoFormacion.vue"),
  },
  {
    path: "/crearFormacion",
    name: "Nueva Formacion",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/general/VideosFormacion/CrearFormacion.vue"),
  },
  {
    path: "/datosFormacion",
    name: "Datos Formacion",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/general/VideosFormacion/datosFormacion.vue"),
  },
  {
    path: "/resumenCuadrantes",
    name: "Resumen Cuadrantes",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/tienda/supervisora/ConsultarCuadrantes.vue"),
  },
  {
    path: "/crearNotasInformativas",
    name: "Crear Notas Informativas",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/general/Notas Informativas/admin/CrearNotasInformativas.vue"
      ),
  },
  {
    path: "/NotasInformativas",
    name: "Notas Informativas",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/general/Notas Informativas/NotasInformativas.vue"),
  },
  {
    path: "/notasInformativasAdmin",
    name: "Notas informativas Admin",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/general/Notas Informativas/admin/NotasInformativasAdmin.vue"
      ),
  },
  {
    path: "/encargosview",
    name: "Encargos view",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/tienda/Encargos/viewEncargos.vue"),
  },
  {
    path: "/colorSemanal",
    name: "Color semanal",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/tienda/ColorSemanal.vue"),
  },
  {
    path: "/listadoSanidad",
    name: "Listado sanidad",
    meta: {
      requiresAuth: true,
    },

    component: () => import("../views/sanidad/ListadoTrabajadores.vue"),
  },
  {
    path: "/inventario-hardware",
    name: "Inventario Hardware",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/administracion/inventariosHardware/inventarioHardwIndex.vue"
      ),
  },
  {
    path: "/chatview",
    name: "Chat",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/general/Chat/ChatView.vue"),
  },
  {
    path: "/perfilUsuarioHardware",
    name: "Perfil Usuario Hardware",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/administracion/usuariosHardware/PerfilHardwareIndex.vue"
      ),
  },
  {
    path: "/firmarDocumento",
    name: "Firmar documento",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/FirmarDocumento.vue"),
  },
  {
    path: "/sanidad",
    name: "Sanidad",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/sanidad/MenuSanidad.vue"),
  },
  {
    path: "/videosFormativos",
    name: "Vídeos formativos",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/VideosFormativos.vue"),
  },
  {
    path: "/presentaciones",
    name: "Presentaciones",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Presentaciones.vue"),
  },
  {
    path: "/cuestionarios",
    name: "Cuestionarios",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Cuestionarios.vue"),
  },
  {
    path: "/documentosParaFirmar",
    name: "Documentos para firmar",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/DocumentosParaFirmar.vue"),
  },
  {
    path: "/formaciones",
    name: "Formaciones",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Formaciones.vue"),
  },
  {
    path: "/realizarFormacion/:id",
    name: "Realizar formacion",
    component: () => import("../views/RealizarFormacion.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/firmaCorrecta",
    name: "Firma correcta",
    component: () => import("../views/FirmadoCorrectamente.vue"),
  },
  {
    path: "/formacionFinalizada",
    name: "Formación finalizada",
    component: () => import("../views/FormacionCompletadaView.vue"),
  },
  {
    path: "/fichajesEmpresa",
    name: "Fichajes Empresa",
    component: () => import("../components/administracion/FichajesEmpresa.vue"),
  },
  {
    path: "/logger",
    name: "Logger",
    component: () => import("../views/LoggerView.vue"),
  },
  {
    path: "/resumenVacaciones",
    name: "Resumen Vacaciones",
    component: () =>
      import("../components/tienda/supervisora/ResumenVacaciones.vue"),
  },
  {
    path: "/notificacionHorasExtras",
    name: "Notificacion horas Extras",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/tienda/supervisora/NotificacionHorasExtras.vue"),
  },
  {
    path: "/resumenHorasExtrasAdmin",
    name: "Resumen horas Extras",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../components/rrhh/ResumenHorasExtrasAdmin.vue"),
  },
  {
    path: "/kpisViewsSupervisoras",
    name: "kpis Views Supervisoras",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../components/tienda/supervisora/KPIsViewsSupervisoras.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const logeado = await checkLogin();

  if (requiresAuth && !logeado) {
    next("login");
  } else if (to.path === "/login" && logeado) {
    next(from.path);
  } else {
    next();
  }
});

export default router;
// test
